import React from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal";

const ClothingBrands = ({ title, caption }) => (
  <section className="brand-logos">
    <div className="container">
      <div className="brand-text">
        <h3>{title}</h3>
        <p className="lead">{caption}</p>
      </div>
      <Fade triggerOnce>
        <div className="logo-strip">
          <div className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 163">
              <path d="M440.9,129c51.5,0,102.9,0,154.4-0.1c3.7,0,4.8,0.8,4.7,4.6c-0.3,8.5-0.3,17,0,25.4c0.1,3.3-0.9,4.1-4.1,4.1c-58.5-0.1-116.9-0.1-175.4-0.1c-39.8,0-79.5,0-119.3,0.1c-2.9,0-4.4-0.9-5.7-3.4c-4.2-8.6-8.7-17.1-13.1-25.6c-2-3.9-1.4-5,3-5C337.3,129,389.1,129,440.9,129z"/>
              <path d="M398.6,57.5c0,8.9-1,17.6-3.6,26.2c-6.2,20.5-20.8,31.1-42.3,30.7c-4.2-0.1-8.4-0.4-12.5-1.2c-16.6-3.1-26.1-13.7-30.4-29.5c-4.5-16.9-4.8-34,0-50.9c4.9-17.5,16.9-28,34.5-31.6c16.7-3.4,31.6,0.7,42.9,14.1c6.5,7.7,8.6,17.2,10.2,26.9C398.3,47.2,398.6,52.3,398.6,57.5z M375.3,58c0-4.5,0.2-9.1-0.1-13.6c-0.2-4.5-1.2-9-2.7-13.3c-2.6-7.3-7.8-11.3-15.4-12.5c-11.7-1.8-19.3,1.3-23.5,9.9c-1.2,2.4-2,5-2.7,7.6c-1.5,6-1.3,12.2-1.7,18.3c-0.6,9.8,0.1,19.6,3.3,29c1.7,5,4.3,9.4,9.4,11.7c14,6.5,28.2-0.3,31.9-15.1C375.7,72.7,375.4,65.3,375.3,58z"/>
              <path d="M416.7,56.8c0-17.2,0-34.4,0-51.6c0-1.5-0.5-3.1,2.1-3.2c15.2-0.8,30.5-1.4,45.7,0.5c16.8,2.1,25,11.7,26.1,29.4c0.8,13.9-3.7,25.3-16.3,32.6c-1,0.6-2,1-1.4,2.5c2.6,6.2,4,12.9,7.6,18.7c2.6,4.2,5.6,7.8,11.1,8.2c1.5,0.1,1.8,0.9,1.7,2.2c0,4.4-0.1,8.8,0,13.3c0,1.7-0.7,2.1-2.2,2.1c-2.9-0.1-5.9,0-8.9-0.2c-6.8-0.5-11.4-4.2-14.4-10.1c-4.8-9.1-9.5-18.3-14-27.6c-1.3-2.6-2.6-3.7-5.5-3.4c-2.4,0.3-4.9,0.2-7.4,0c-2.6-0.2-3.4,0.7-3.3,3.3c0.1,11.4-0.1,22.8,0.2,34.3c0.1,3.6-1.1,4.4-4.4,4.2c-4.5-0.3-9.1-0.2-13.7,0c-2.4,0.1-3.2-0.6-3.1-3.1c0.2-8.7,0.1-17.4,0.1-26.2C416.7,74,416.7,65.4,416.7,56.8z M437.7,35.9c0,4.9,0,9.8,0,14.7c0,1.6,0.2,2.6,2.1,2.5c5.2-0.1,10.3,0.2,15.5-0.2c7.4-0.5,11.8-4.7,13.3-12c0.8-4.2,0.7-8.3-0.1-12.4c-0.9-4.3-3.2-8-7.6-9.1C454,17.7,447,17,440,18.6c-1.7,0.4-2.4,1.1-2.4,3C437.8,26.4,437.7,31.1,437.7,35.9z"/>
              <path d="M215,57.1c0-17.1,0-34.1,0-51.2c0-2.1,0.2-3.5,2.8-3.5c14-0.3,28-1.1,42,0.3c19.1,2,27.1,9.9,28.9,28.9c1.3,14-3.3,25.6-15.9,33.2c-1.1,0.7-2.4,1.1-1.6,3c2.3,5.4,3.6,11.2,6.5,16.4c2.8,5,5.9,9.8,12.5,10.2c1.4,0.1,1.5,1,1.5,2c0,4.4-0.1,8.8,0,13.3c0,1.8-0.7,2.3-2.4,2.3c-3.8,0-7.6-0.1-11.4-0.8c-5.5-1.1-9.1-4.7-11.6-9.4c-4.9-9.3-9.7-18.8-14.4-28.2c-1.1-2.1-2.1-3.5-4.7-2.8c-0.5,0.1-1,0-1.5,0c-3.1,0.2-7.4-1.4-9.1,0.7c-1.6,2-0.5,6.1-0.6,9.3c-0.1,9.5-0.2,18.9-0.1,28.4c0,2.5-0.9,3.2-3.2,3.1c-4.9-0.1-9.8-0.2-14.8,0c-2.4,0.1-3-0.8-3-3.2C215,91.7,215,74.4,215,57.1z M236,36.8c0,4.7,0.1,9.3-0.1,14c-0.1,2.5,0.8,3.2,3.1,3c3.4-0.2,6.9,0,10.3-0.1c12.8-0.1,18.9-6.8,17.9-19.5c-0.2-2.1-0.5-4.1-1-6.2c-1-4-3.3-7.1-7.3-8.1c-6.8-1.8-13.8-2.3-20.8-0.8c-1.8,0.4-2.4,1.2-2.4,3C236,27,236,31.9,236,36.8z"/>
              <path d="M188.9,43c0,12.4,0.5,24.8-0.1,37.2c-0.9,18.6-9.9,28.6-28.1,32.8c-10.3,2.3-20.5,1.9-30.6-1.2C116.6,107.6,109,98.1,108,84c-0.4-5.5-0.6-11-0.6-16.5c0-20.5,0.1-41,0.1-61.5c0-2.5,0.6-3.4,3.2-3.4c4.5,0.1,9.1,0.1,13.6-0.3c2.8-0.2,3.5,0.7,3.4,3.4c-0.1,20.9-0.1,41.8-0.1,62.6c0,5.3,0.5,10.5,1.7,15.7c1.2,5.6,4.6,9.1,10.1,10.7c5.9,1.7,11.9,1.7,17.8,0c5.1-1.5,8.5-4.8,10-9.9c1.4-4.6,1.9-9.4,1.9-14.2c0-21.5,0.1-43-0.1-64.5c0-3.1,0.8-4,3.9-3.8c4.5,0.3,9.1,0.2,13.7,0c2.5-0.1,3.1,0.7,3.1,3.1c-0.1,12.5,0,25,0,37.6C189.5,43,189.2,43,188.9,43z"/>
              <path d="M599.1,112.7c-6.5,0-12.9,0-19.2,0c-1.8,0-1.8-1.3-2.2-2.4c-2.4-7.7-4.9-15.5-7-23.3c-0.7-2.4-1.8-3-4.1-2.9c-9,0.1-18,0.1-26.9,0c-2.2,0-3.2,0.7-3.9,2.8c-2.4,7.7-4.9,15.4-7.3,23.2c-0.6,1.9-1.4,2.8-3.6,2.7c-5-0.2-10.1-0.2-15.1-0.2c-2.1,0-3-0.5-2.2-3c10.4-34.7,20.7-69.3,30.9-104.1c0.6-2.1,1.5-2.7,3.5-2.6c7.7,0.1,15.5,0.1,23.2,0c2.2,0,2.9,0.8,3.5,2.7c10,35.2,20.1,70.3,30.2,105.4C599,111.4,599,111.8,599.1,112.7z M553.1,20.2c-0.2,0-0.4,0.1-0.7,0.1c-0.3,1.3-0.6,2.6-1,3.8c-3.4,13.3-6.8,26.6-10.2,39.9c-0.6,2.2-0.4,3,2.2,3c6.8-0.2,13.5-0.1,20.3,0c1.7,0,2.4-0.2,1.8-2.2C561.4,49.9,557.3,35,553.1,20.2z"/>
              <path d="M45.7,84.1c-4.4,0-8.9,0.2-13.3-0.1c-2.5-0.1-3.4,0.9-4.1,3.1c-2.3,7.6-4.9,15.2-7.2,22.8c-0.6,2.1-1.5,3-3.8,2.8c-4.8-0.2-9.6-0.2-14.4-0.2c-2.4,0-3.5-0.6-2.6-3.4C10.6,74.6,20.9,40.1,31.1,5.5c0.7-2.2,1.7-2.8,3.9-2.7c7.4,0.1,14.8,0.2,22.1,0c2.7-0.1,3.8,0.8,4.5,3.4c9.3,32.7,18.6,65.3,28,98c0.8,2.7,3.1,6.1,1.8,7.9c-1.5,2.1-5.5,0.5-8.3,0.7c-2.9,0.1-5.9-0.1-8.9,0.1c-2.5,0.2-3.3-0.8-4-3.1c-2.1-7.6-4.6-15-6.8-22.6c-0.6-2.2-1.6-3.2-4-3.1C54.8,84.3,50.3,84.1,45.7,84.1z M45.5,18.9c-4.1,15.9-7.9,31-11.7,46c-0.6,2.4,0.9,2,2.2,2c6.5,0,13-0.2,19.5,0.1c3.3,0.1,3.2-0.9,2.5-3.5C53.8,49,49.8,34.5,45.5,18.9z"/>
            </svg>
          </div>
          <div className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 223">
              <path d="M288.7,29.7c-11.1,1.6-22.3,2.3-33.5,2C228.3,31,236.7,57,236.7,83.7c0,11.8-0.4,23.5-0.4,35.2c0.1,11.8-0.9,23.2-0.8,35.2s0.9,22.8,1.5,34.1c-0.1,5.2,0.9,10.4,3.1,15.1c7.3,13,29.1,11.2,41.9,11.6c11.5,0.9,23.1,0.9,34.6,0c12.7-1.5,17.7-3.9,18.5-17.2c0-6-4.7-59.4-5.3-66c-1.4-11.1-2.3-22.8-2.3-33.6s-1.9-23.1-2-34.1c1-9.9-2-19.7-8.2-27.4c-7.9-6.4-18.2-9.1-28.2-7.3L288.7,29.7z M280.2,64.9c4.5,0,13.7-1.4,15.6,2.3c1.2,2.2,6.5,76.7,7.3,85.2c1.9,21.8,2.3,21.4-13.1,21.8c-5.7,0.2-11.4-0.3-16.9-1.7c-3.5-1.2-2.8-2.9-2.9-6.8c-0.9-24-2-55.6-3.9-78.8c-0.7-5.7-0.6-11.5,0.4-17.2c1.6-5.3,5.7-3.9,13.5-4.5L280.2,64.9z"/>
              <path d="M8.6,0.6C-1.1,1.5,0,2.3,0,18.2C0,27,0.7,35.3,0.8,44c0,17.2,0.7,34.4,1.2,51.5s1.7,33.6,1.7,50.9c0,15.2-1.5,50.4,1.5,63.9c1,4.5,3,5.2,7.6,5.9c8.4,0.7,16.8,0.4,25.1-0.8c7.9-0.7,18.2,0,26.3,0s16.7,0.4,25.2,0.9c12.7,0.9,15.9,1.7,14.5-11.7c-0.8-9.1,0-17.3,0-26.3c0-24.7-4.7-25.4-28.2-22.5c-10.2,1.4-2.9,14-10.2,15.5c-4.2,0.3-8.5,0.3-12.7,0c-5.1,0-7.3,0-8.1-5.2c-0.4-4-0.4-8,0-12.1c0-9.8-1.8-26.3-2.3-37.5c-0.7-17-1.5-33.9-2-50.9c0-10.4-0.4-12.9,14.5-12.5c11.9,0,14.5,0,13.9,12.5c-0.4,14.1,0,12.7,13.6,12.7c12.8,0,12.9-0.5,13.5-12.5c0.8-16.8-0.4-34.1-0.5-50.9C95.6-7.3,81.6,3.4,59.7,1.5C42.7-0.1,25.6-0.4,8.6,0.6z"/>
              <path d="M361.2,14.1c-6,0.7-7.9,1.4-8,8v15.7c0,10.2,1.8,19.3,2.2,29.1c0.7,20.3,2,40.2,3.1,60.5c0.7,12.3,1.3,78,7.3,86.5c2.5,3.5,7.7,3.1,12.7,3.1c4.9,0,11.6,0.9,14.5-1.3s2.5-8.3,2.2-13.3l-2.8-43.6c-1.2-18.7,15.8-11.8,32.8-11.6c4.7,0,10.3,0,12.5,3.6c1.6,4.4,2,9.2,1,13.7c-1.1,13,2.3,30.9,2.7,44.7c0.4,13.8,4.7,8.6,21.3,7.8c16.7-0.8,12.7-3.8,11.4-18.4c-0.8-9.7-1.8-19.3-2.5-29.1c-1.3-20.3,2.8-30.7-19.3-43.6c-13.7-7.8-23.2-5.9-4-21.8c11.8-9.9,13.9-16,13.9-33.2c0-10.5-0.8-20.6-0.8-30.7c0-13.3,0.9-18.7-14.5-17.3c-16.9,1.6-14.5-1.4-14.5,16.1c0,10.8,3.6,51.4-3.6,58.1c-3.2,2.8-8.9,2.3-13.5,2.3c-17.7,0-27,6.4-28.2-17.5c-0.4-10.2-0.5-20.6-0.9-31c-0.3-9.4-1.5-18.7-3.5-27.8c-1-4.7-1.3-8.4-6.7-8.9C371,13.7,366.1,13.7,361.2,14.1L361.2,14.1z"/>
              <path d="M581.5,223h6.7l4-1.3c3.6-1.8,3.9-8.2,3.3-11.8c-1.2-8.4-6.3-6.4-13.6-6.4c-7.3,0-13.7,0-20.7,0s-15.6,1.7-17.4-3.8c-0.2-6.3,0.1-12.7,0.9-19c0-11.6-8.6-15.4,25.4-15.5c5.2,0,16.1,2.9,18-1.8c2.2-5.5,0.4-11.8-4.4-15.3c-6.4-1.8-13-2.4-19.6-1.8c-21.8,0-18.9-1.8-18.8-22.7c0-24.7-5.7-28.2,14.5-25.1c6,0.9,12.8,0.9,19.1,1.7c6.3,0.7,13.9,3.6,19.3,1.3c2.7-2.6,0-10.5,0-14.2c0-14.5,2.2-31.1,1.7-49.3c-1.2-7.3-6.4-6-18-6c-24.4,0-41.2-2.2-68.9,0c-10.5,0.7-10.1,3.2-10.3,12.9v23.3c0.4,15.1,0,30.6,0.6,45.7c1,29.1,0.7,63.9,2.3,91.1c0.9,15.2,2.5,12.8,32,14.5c10.4,0.7,31.9,0.9,41.5,2.4L581.5,223z"/>
              <path d="M131.9,24.6c-6.1,0.8-8.3,2.5-8.9,8.7c-0.4,5.6,0,11.3,1,16.8c3.9,26.9,3.8,74.5,4.4,102.4c0,11.4,0.7,22.8,1.1,34.1c0,5.7,0.4,11.7,0.9,17.3c0.7,7.3,1.5,8.6,9.3,8.5s69,0.6,71.1-0.7c4.9-2.9,2.5-25.6,2.6-32.5s1.7-11.2-5-12.7c-6.8-1.5-44.2,4.6-46.6-5.4c0.7-4.9,1.6-9.7,2.8-14.5c0.7-5.6,1.2-10.8,1.6-16.3c1-22.9,0.4-45.9-1.7-68.8c-0.7-9.7,1.7-24-2-32.6C160.4,24.1,136.4,24.1,131.9,24.6z"/>
            </svg>
          </div>
          <div className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 491">
              <path d="M500.1,168.8c-26-37.5-92.7-42.9-121.8-49.9s-28.6-32.9-28-39.6c0.6-6.7,4.9-32.8,51.5-32.8S448,79.7,448,79.7h56.1c0,0,1.5-18-6-34.5C490.6,28.7,461.4,0,399.9,0c-50.1,0-84.5,22.7-94.8,38.1v111l55.5,15.6l11.7,3.3h0.1c20.8,6,46.8,13.4,60.3,17.7c24,7.6,26.7,22.6,28,28.4c1.3,5.8,0.8,29-22.8,40.5c-18.5,9.1-43,11.6-70.3,4.7c-26.9-6.8-26-36.5-26-36.5V170l-36.4-10.3V280c10.5,14.5,44.3,29.4,87,29.2c59.3-0.3,84.1-13.7,101.6-31.6C516.1,254.2,526.2,206.3,500.1,168.8z"/>
              <path d="M294.6,37.7C283.8,22.3,249.6,0,200,0c-61.5,0-90.8,28.8-98.3,45.3c-7.5,16.5-6,34.5-6,34.5h56.1c0,0-0.3-33.2,46.2-33.2s50.8,26,51.5,32.8c0.7,6.8,0.9,32.4-28,39.6c-29,7.2-95.8,12.3-121.9,49.8s-16,85.3,6.6,108.7c17.4,18,42.3,31.3,101.5,31.6c42.5,0.3,76.2-14.5,86.8-28.9L294.6,37.7z M232.6,259.2c-27.3,6.9-51.9,4.3-70.3-4.7c-23.6-11.5-24-34.7-22.8-40.5c1.2-5.8,4-20.7,28-28.4c24.4-7.7,91.2-26.3,91.2-26.3v63.5C258.6,222.7,259.5,252.4,232.6,259.2z"/>
              <path d="M84.2,442c-1.5,11.3-6.2,19.4-13.7,23.5c-4.5,2.8-10,3.3-13.8,3.3c-2.3,0-8.3,0-14-2.7c-2.9-1.4-5.4-3.5-7.6-6c-6.3-7.6-9.2-18.1-9.2-33c0-16.6,4.3-28.6,12.7-35.3c4.9-3.9,11.1-6,17.4-5.9C71,386,77,395.5,79,399.7c1.3,3.2,2.2,6.5,2.7,9.9l0.5,2.9l25.6-3.8l-0.3-2.3c-1.3-10-4.4-17.9-9.2-23.9c-2.6-3.7-5.8-7-9.4-9.7c-5.6-3.8-15.8-8.3-32.3-8.3c-6.9-0.1-13.7,0.8-20.2,2.9c-8.3,2.9-15.8,8-21.6,14.7C5,393.2,0,408.8,0,427.3c0,7.7,0.9,15.5,2.8,23c4.8,18.6,14.1,26.9,21.6,32.2c12.7,8.5,26.6,8.5,31.8,8.5c18.2,0,29.1-6,34-9.5c14.9-11,18.3-29.8,19.3-36l0.6-3.1l-25.5-2.6L84.2,442z"/>
              <path d="M177.3,469.7c-5.9,0.1-11.7-1.3-16.9-4.2c-3.8-2.3-7.1-5.2-9.4-8.9c-1.8-3.1-6.3-11.9-6.3-28.3c0-17.1,4.6-29.6,13.3-36.3c3.4-2.6,8.9-6,18.8-6c22.3,0,29.5,15.9,31.5,29.4l0,0c0.7,4.1,1,8.2,1.1,12.3c0,18.3-4.4,29.9-14,36.7C190.2,468.1,183.9,469.9,177.3,469.7 M214.2,375.9c-14.7-11.6-30.2-11.6-36.8-11.6c-5.8,0-21.2,0-35.3,10.4c-3.9,2.7-7.4,6-10.3,9.8c-5.8,7.5-12.7,21.2-12.7,43.6c0,41.7,22.3,55.5,35.6,60c6.9,2.1,14,3,21.1,2.9c7.4,0,17-0.5,26-4l0,0c7.9-3.3,33.6-17.3,33.6-59.1C235.4,405,228,387.1,214.2,375.9"/>
              <rect x="250.2" y="343.9" width="25.3" height="144.9"/>
              <path d="M329.2,392.1c5.1-4,11.3-6,18.8-6c22.3,0,29.4,15.9,31.4,29.4l0,0c0.6,4.1,1,8.2,1.1,12.3c0,18.3-4.4,29.9-14,36.7c-5.3,3.6-11.7,5.4-18.2,5.2c-6,0.1-11.7-1.3-16.9-4.2c-3.8-2.2-7.1-5.2-9.4-8.9c-1.8-3.1-6.3-11.9-6.3-28.3C315.8,411.3,320.5,398.7,329.2,392.1 M385.3,375.9c-14.7-11.6-30.2-11.6-36.8-11.6c-5.8,0-21.2,0-35.3,10.4c-3.9,2.7-7.3,6-10.2,9.8c-5.9,7.5-12.7,21.2-12.7,43.6c0,41.7,22.3,55.5,35.6,60c6.8,2.1,14,3,21.1,2.9c7.4,0,17-0.5,26-4l0,0c7.9-3.3,33.7-17.2,33.7-59.1C406.4,405,399.1,387.1,385.3,375.9"/>
              <path d="M492.5,424.4c0,15.6-0.6,22.1-4.3,29.1c-4.8,9.2-14.5,15-24.9,15.1c-10.8,0-15.4-6.2-16.8-8.7c-2.9-5.5-3-13.8-3.1-26.4v-66.9h-25.7v62.3c0,9.8-0.1,23,1.6,31.4c3.6,17.9,15.8,25,22.8,27.5c3.5,1.4,9.4,3.1,18.3,3.1c13.3,0,20.5-3.7,24.7-6.5c3.2-1.8,6.1-4.2,8.5-6.8v11h24.3V366.6h-25.5v57.8H492.5z"/>
              <path d="M599.8,391.5L600,367l-2-0.3c-3.1-0.5-6.3-0.7-9.3-0.7c-10.8,0-19.1,3.3-26.1,10.3c-1.3,1.3-2.6,2.8-3.8,4.3v-13.9h-24.1v122.1h25.1V447c0-11.4,0.1-23.8,1.1-30c2.2-12.9,8.5-18.4,13.1-21.4c6.9-4.3,15.2-5.9,23.2-4.4L599.8,391.5z"/>
            </svg>
          </div>
          <div className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 46">
              <path d="M61.2,1.2C27,1.2,0,11.4,0,24.5c0,8.4,12,16.1,30,20.3c3.6-7.2,7.8-11.9,10.2-14.3C30,28.1,23.4,23.9,23.4,18.5c0-7.8,17.4-14.3,38.4-14.3s38.4,6.6,38.4,14.3c0,4.8-6.6,9-16.8,11.9c2.4,2.4,6.6,7.2,10.2,14.3c18-4.2,30-11.4,30-20.3C123,11.9,95.4,1.2,61.2,1.2"/>
              <path d="M177.6,22.1c2.4-1.8,4.8-4.8,4.8-9C182.4,5.4,177,0,168,0h-18v46h19.2c9,0,14.4-5.4,14.4-13.7C183,28.1,181.2,24.5,177.6,22.1z M167.4,18.5H159V8.4h8.4c1.8,0,6,0.6,6,4.8C173.4,17.9,169.2,18.5,167.4,18.5z M159,26.3h9c3.6,0,6,1.8,6,5.4c0,3.6-2.4,5.4-6,5.4h-9V26.3z"/>
              <rect x="189.6" y="0" width="9" height="46"/>
              <polygon points="235.8,0 206.4,0 206.4,8.4 224.4,8.4 205.8,38.8 205.8,46 235.8,46 235.8,37.6 216.6,37.6 235.8,6.6 "/>
              <path d="M268.8,44.8c-4.8,0-8.4-1.8-11.4-4.8c-4.2-4.2-4.2-9-4.2-17.3s0-13.1,4.2-17.3c3-3,7.2-4.8,11.4-4.8c8.4,0,13.8,4.8,15.6,13.7h-6.6c-1.2-4.8-4.2-7.8-9-7.8c-2.4,0-4.8,1.2-6.6,3c-2.4,2.4-3,4.8-3,13.7s0.6,11.4,3,13.7c1.8,1.8,3.6,3,6.6,3c4.8,0,7.8-3,9-7.8h6.6C283.2,40.6,277.2,44.8,268.8,44.8"/>
              <path d="M319.2,40.6c-3,3-6.6,4.8-11.4,4.8s-8.4-1.8-11.4-4.8c-4.2-4.2-4.2-9-4.2-17.3s0-13.1,4.2-17.3c3-3,6.6-4.8,11.4-4.8S316.2,3,319.2,6c4.2,4.2,4.2,9,4.2,17.3C323.4,31.1,323.4,35.8,319.2,40.6 M313.8,9.6c-1.8-1.8-4.2-3-6.6-3c-2.4,0-4.8,1.2-6.6,3c-2.4,2.4-2.4,4.8-2.4,13.7s0.6,11.4,2.4,13.7c1.8,1.8,4.2,3,6.6,3s4.8-1.2,6.6-3c2.4-2.4,2.4-4.8,2.4-13.7C316.8,14.3,316.2,11.9,313.8,9.6"/>
              <polygon points="332.4,44.8 332.4,1.2 339,1.2 339,38.8 360,38.8 360,44.8 "/>
              <polygon points="367.2,44.8 367.2,1.2 373.8,1.2 373.8,38.8 394.8,38.8 394.8,44.8 "/>
              <polygon points="402.6,44.8 402.6,1.2 430.2,1.2 430.2,7.2 408.6,7.2 408.6,19.7 427.2,19.7 427.2,25.7 408.6,25.7 408.6,38.8 430.2,38.8 430.2,44.8 "/>
              <path d="M451.8,44.8c-4.8,0-8.4-1.8-11.4-4.8c-4.2-4.2-4.2-9-4.2-17.3s0-13.1,4.2-17.3c3-3,7.2-4.8,11.4-4.8c8.4,0,13.8,4.8,15.6,13.7h-6.6c-1.2-4.8-4.2-7.8-9-7.8c-2.4,0-4.8,1.2-6.6,3c-2.4,2.4-3,4.8-3,13.7s0.6,11.4,3,13.7c1.8,1.8,3.6,3,6.6,3c4.8,0,7.8-3,9-7.8h7.2C466.2,40.6,460.2,44.8,451.8,44.8"/>
              <polygon points="491.4,7.2 491.4,44.8 484.8,44.8 484.8,7.2 472.8,7.2 472.8,1.2 503.4,1.2 503.4,7.2 "/>
              <rect x="510.6" y="1.2" width="6.6" height="43.6"/>
              <path d="M553.8,40.6c-3,3-6.6,4.8-11.4,4.8c-4.8,0-8.4-1.8-11.4-4.8c-4.2-4.2-4.2-9-4.2-17.3s0-13.1,4.2-17.3c3-3,6.6-4.8,11.4-4.8c4.8,0,8.4,1.8,11.4,4.8c4.2,4.2,4.2,9,4.2,17.3C558,31.1,558,35.8,553.8,40.6 M548.4,9.6c-1.8-1.8-4.2-3-6.6-3c-2.4,0-4.8,1.2-6.6,3c-2.4,2.4-2.4,4.8-2.4,13.7s0.6,11.4,2.4,13.7c1.8,1.8,4.2,3,6.6,3c2.4,0,4.8-1.2,6.6-3c2.4-2.4,2.4-4.8,2.4-13.7C551.4,14.3,550.8,11.9,548.4,9.6"/>
              <polygon points="594,44.8 573.6,13.7 573.6,44.8 567,44.8 567,1.2 573,1.2 593.4,31.7 593.4,1.2 600,1.2 600,44.8 "/>
            </svg>
          </div>
          <div className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 194">
              <path d="M31,139.6c-12.1,0-21.9-6.1-29.2-18.2L0,118.3l18.2-17.7l2.4,6.9c0.9,2.6,2.4,4.7,4.5,6.5c1.9,1.6,3.9,2.4,6,2.4c1.7,0,3.1-0.5,4.1-1.7c1.2-1.5,1.9-3.2,1.9-5.4c0-2.1-0.9-3.7-2.7-5.1c-0.5-0.4-2.5-1.5-8.6-4c-6.7-2.6-11.5-5.6-14.7-9.2c-4.2-4.6-6.5-10.5-6.5-17.5c0-8.1,2.7-15.1,8.1-20.7c5.5-5.6,12.3-8.5,20.4-8.5c4.4,0,9,1,13.8,2.9c5.1,2,9.1,4.6,12,7.9l2.4,2.7L48,77.3l-3.9-5.4c-2.5-3.4-5.1-5-8.5-5c-1.5,0-2.7,0.5-3.9,1.5c-1,0.9-1.5,1.9-1.5,3.1c0,1.5,2.1,3.4,5.7,5C48,82,51.1,83.9,52.5,85c6.7,5.2,10.2,12.7,10.2,22.1c0,9.5-2.9,17.5-8.5,23.4C48.4,136.5,40.6,139.6,31,139.6"/>
              <polygon points="148.2,137.5 123,137.5 123,96.5 94.2,46.4 123.6,46.4 135.2,69.4 147.9,46.4 177.5,46.4 148.2,96.5 "/>
              <polygon points="275.6,137.5 203.9,137.5 239.9,69.6 212.5,69.6 212.5,46.4 280.6,46.4 244.7,114.3 275.6,114.3 "/>
              <polygon points="368.3,138.5 356.2,138.5 343.4,101.2 338.1,137.5 312.6,137.5 327.9,44.1 346.4,44.1 363.2,89.3 379.4,44.1 398.2,44.1 411.8,137.5 386.2,137.5 381.6,101.4 "/>
              <rect x="452.3" y="46.4" width="25.2" height="91.1"/>
              <polygon points="567,46.4 548.5,71.4 548.5,46.4 523.2,46.4 523.2,137.5 548.5,137.5 548.5,110.5 567.7,137.5 600,137.5 563.9,88.9 598.8,46.4 "/>
              <path d="M464.6,0c-21.9,0-39.1,6.7-39.1,15.3c0,5.6,7.2,10.5,19.2,13.2l0.5,0.1l0.2-0.5c2.2-4.5,4.7-7.6,6.1-9l0.7-0.9l-1.1-0.2c-6.2-1.6-10.1-4.1-10.1-6.7c0-4,9.5-6.6,23.4-6.6S488,7.4,488,11.3c0,2.6-3.9,5.2-10.1,6.7l-1.1,0.2l0.7,0.9c1.2,1.4,3.9,4.5,6.1,9l0.2,0.5l0.5-0.1c12-2.7,19.2-7.7,19.2-13.2C503.7,6.7,486.6,0,464.6,0"/>
              <path d="M108.7,152.2l5.2,24.6h0.4c0.2-2.5,0.6-5.1,1.2-7.6l4.4-17h11.5l5.6,24.6h0.4c0.2-2.4,0.5-4.9,1-7.2l4.1-17.3H158l-14.5,41.1h-13l-4.7-22.3h-0.5c-0.1,2.1-0.2,4.2-0.7,6.2l-4.1,16.1h-12.7l-14.3-41.1L108.7,152.2L108.7,152.2z"/>
              <path d="M178.7,173.2c0,4.7,3.1,7.9,7.7,7.9c4.5,0,7.7-3.2,7.7-7.9c0-4.4-3-8-7.7-8C181.7,165.1,178.7,168.8,178.7,173.2M209.1,172.9c0,13.2-10.2,21.1-22.7,21.1c-12.6,0-22.7-7.9-22.7-21.1c0-13,10.2-21.3,22.7-21.3C198.9,151.6,209.1,160,209.1,172.9"/>
              <path d="M233.2,170.4h1c2.5,0,4.9-0.4,4.9-3.5s-2.2-3.5-4.7-3.5h-1.1V170.4z M218.5,152.2h18.1c9.2,0,17.1,3.9,17.1,14.3c0,6.2-2.4,8.9-8,10.7v0.4c0.7,0.6,1.7,1.2,2.5,2.5l9,13.3h-17.2l-6.5-13.2h-0.2v13.2h-14.6v-41.3L218.5,152.2L218.5,152.2z"/>
              <path d="M264,152.2h14.6l-0.4,17.8h0.4c0.4-1.7,1.2-3.1,2.1-4.6l7.2-13.2h16.3l-12.8,19.9l14.2,21.1h-17.3l-7.1-12.5c-0.9-1.6-2.2-3.1-2.5-4.7h-0.4l0.4,17.2h-14.6v-41H264z"/>
              <path d="M324.7,152.2l5.2,24.6h0.4c0.2-2.5,0.6-5.1,1.2-7.6l4.4-17h11.5l5.6,24.6h0.4c0.2-2.4,0.5-4.9,1-7.2l4.1-17.3h15.3l-14.5,41.1h-13l-4.7-22.3h-0.5c-0.1,2.1-0.2,4.2-0.7,6.2l-4.1,16.1h-12.7l-14.3-41.1L324.7,152.2L324.7,152.2z"/>
              <polygon points="381,152.2 381,193.4 409.1,193.4 409.1,182.2 396,182.2 396,178 407.6,178 407.6,167.4 396,167.4 396,163.5 408.6,163.5 408.6,152.2 "/>
              <path d="M441.2,178.4l-1.7-7.2c-0.5-1.7-0.7-3.5-1-5.2H438l-2.7,12.6h6V178.4z M431.4,193.4h-15.8l14.3-41.1h16.5l14.7,41.1h-15.8l-1.4-5.1h-11.2L431.4,193.4z"/>
              <path d="M482.8,170.4h1c2.5,0,4.9-0.4,4.9-3.5s-2.2-3.5-4.7-3.5h-1.1V170.4z M468.3,152.2h18.1c9.2,0,17.1,3.9,17.1,14.3c0,6.2-2.4,8.9-8,10.7v0.4c0.7,0.6,1.7,1.2,2.5,2.5l9,13.3h-17.2l-6.5-13.2H483v13.2h-14.6L468.3,152.2L468.3,152.2z"/>
            </svg>
          </div>
          <div className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 421">
              <path d="M42.7,341.5c-3.9-2.1-8.2-3.2-12.6-3.2c-15.9-0.6-29.4,11.8-30,27.7c0,0.5,0,1.1,0,1.6c0.1,15.7,13,28.3,28.7,28.2c0.2,0,0.4,0,0.7,0c4.7,0,9.3-1,13.5-3.1v-16.2c-2.6,2.3-6,3.6-9.6,3.7c-7.1,0.4-13.1-5.1-13.5-12.1c0-0.4,0-0.8,0-1.1c-0.1-7.2,5.7-13,12.8-13.1c0.1,0,0.2,0,0.3,0c3.7,0.2,7.2,1.5,10,3.8L42.7,341.5z"/>
              <path d="M82.8,355C82.8,355,82.8,355,82.8,355C82.7,355,82.7,355,82.8,355L82.8,355z"/>
              <path d="M113.6,394l-0.1-54H94.4v5.3c-4.3-4.7-10.4-7.2-16.7-7.1c-15.9,0.9-28,14.6-27,30.4c0.9,14.6,12.5,26.2,27,27c6.4,0.4,12.6-2.5,16.5-7.7v6H113.6z M82,378.7c-6.2-0.4-11.1-5.3-11.5-11.5c-0.3-6.5,4.8-11.9,11.2-12.2c0.4,0,0.7,0,1.1,0c6.4-0.3,11.9,4.6,12.3,11.1c0,0.4,0,0.7,0,1.1C94.6,374,88.7,379.1,82,378.7z"/>
              <path d="M145.3,340.2h-19.1v53.9h19.1v-28c0-6.7,2.2-12.5,10-12.5c9.5,0,8.6,9.1,8.6,14.2v26.3H183v-33.3c0-13.3-5.8-22.4-20.3-22.4c-6.9-0.5-13.5,2.8-17.2,8.7L145.3,340.2z"/>
              <path d="M216.5,356h10.9v-15.8h-10.9V324h-19.1v16.2h-6.2V356h6.2v38.1h19.1V356z"/>
              <path d="M288.8,370.3v-2c0-18.4-10.8-30-29.5-30c-17.6,0-30,10.8-30,28.8s13.4,28.7,31.2,28.7c12.3,0,24.4-5.8,27.4-18.5h-18.4c-1.8,3.3-5.4,5.3-9.2,5c-5.9,0.5-11.1-3.8-11.6-9.7c-0.1-0.8-0.1-1.5,0-2.3H288.8z M262.7,351.1c4,1.1,7.1,4.2,8.2,8.2h-22.2C250.3,353.2,256.6,349.5,262.7,351.1z"/>
              <path d="M316.6,340.2h-19.1v53.9h19.1v-21.8c0-9.3,2.6-16.3,13.2-16.3c2.8-0.1,5.6,0.5,8.1,1.8v-18.4h-3.7c-7.2-0.4-13.9,3.3-17.4,9.6L316.6,340.2z"/>
              <path d="M377.1,355C377.1,355,377.1,355,377.1,355C377.1,355,377.1,355,377.1,355L377.1,355z"/>
              <path d="M408.7,365.3c-0.9-14.6-12.5-26.2-27-27c-6.3-0.1-12.4,2.4-16.7,7.1v-38.5l-19.1,4.2l0.5,83.1h19.1V388c3.8,5.1,9.9,8.1,16.3,7.8C397.5,394.8,409.6,381.2,408.7,365.3z M376.3,378.7c-6.2-0.4-11.1-5.3-11.5-11.5c-0.3-6.5,4.7-11.9,11.2-12.2c0.4,0,0.7,0,1.1,0c6.4-0.3,11.9,4.6,12.3,11.1c0,0.4,0,0.8,0,1.1C389,374,383.1,379.2,376.3,378.7z"/>
              <path d="M416.4,340.2v32.5c0,17.8,12.5,23.2,28.7,23.2s28.8-5.5,28.8-23.3v-32.4h-19.1V369c0,6.8-1.7,11.7-9.6,11.7s-9.6-5-9.6-11.7v-28.8L416.4,340.2z"/>
              <path d="M502.7,340.2h-19.1v53.9h19.1v-21.8c0-9.3,2.6-16.3,13.2-16.3c2.8-0.1,5.6,0.5,8,1.9v-18.5h-3.7c-7.2-0.4-13.9,3.3-17.4,9.6L502.7,340.2z"/>
              <path d="M553.6,386.8L535.2,421h21.4l43.2-80.8H578L564,367.8l-14.4-27.6h-22.1L553.6,386.8L553.6,386.8z"/>
              <path d="M158.7,0.1c3.8,0,7.6,0.2,11.4,0.7c9.7,0.9,19.3,2.8,28.7,5.5c2,0.8,4.3,1.3,6.3,2.1c3.8,1.3,7.5,2.8,11.1,4.5c-10,7.1-19.6,14.9-28.6,23.2c-1.7,1.6-2.8,3.2-4.4,5c-1.3,1-2.4,2.1-3.4,3.4c-0.9,1.2-2.2,2.2-3,3.4l-1.2,1.6c-1,0-1.7-0.9-2.6-1c-6.9-3.3-14.3-5.4-22-6c-0.9-0.5-2,0-3-0.4c-1-0.4-2.7,0-4.4,0c-3.9-0.1-7.8,0.4-11.5,1.6c-2.9,0.6-5.7,1.5-8.4,2.9c-4.1,1.8-7.9,4.3-11.3,7.3c-1.1,1.8-3.2,2.9-4.1,5c-0.6,0.5-1.1,1.1-1.4,1.8h-0.4c-2.3,2.6-4.2,5.6-5.7,8.7c-3.1,4.7-5.5,9.8-7.4,15.1c-1,2.5-1.5,4.2-2.4,6.8c-0.9,2.6-1.3,4.5-2.3,7.1c-1.3,3.9-3.1,7.7-5.4,11.2c-1.2,1.3-1.7,2.3-2.7,3.3c-2.6,2.8-5.5,5.6-8.5,8.4s-5.6,5-8.4,7.6c-7.2,6.6-14.2,12.8-21.4,19.4c-0.5,0.3-0.9,0.6-1.3,1.1c-4.7,4.4-10,8.5-14.4,12.9c-0.7,0.7-2,1.6-2.6,2.1c-6.5,6-13.6,11.9-20,18.2c-0.3,0.3-0.6,0.6-1,0.8c0-0.6,0-1.4-0.4-1.9c-0.5-1.6-0.8-3.3-0.9-5c-0.8-3.8-1-7.1-1.6-11c0-3-0.5-6.3-0.7-9.5v-5c0.2-7.3,0.8-14.6,1.9-21.8c0-0.6,0-1.1,0.4-2.1c0-2.5,0.6-3.8,1-6.1c0-0.9,0.4-1.5,0.5-2.1c0.5-1.8,0.8-3.8,1.4-5.6c0.1-1,0.4-2,0.8-3c1.8-6.1,3.9-12.1,6.4-17.9c0.6-0.3,0-1,0.6-1.4c2.3-4.5,4.3-9.1,6.8-13.6c0.7-1.3,1.5-2.4,2.4-3.6c3.2-5.5,6.9-10.7,10.9-15.7c5.6-6.7,11.6-13,18-18.9c4.2-3.6,8.5-6.9,12.8-10l1.9-1.3c2.3-1.4,4.2-3,6.4-4.2c3.1-2.1,6.3-3.8,9.4-5.6c1,0,1.7-1.1,2.7-1.2c2.6-1.2,5-2.6,7.7-3.6c1.7-0.8,2.5-1.1,4.1-1.9l4.2-1.6c1.6-0.5,2.9-1.1,4.4-1.5l2.8-1c7.5-2.3,15.2-4.1,23-5.4c7.2-1,14.5-1.7,21.9-1.9c2,0.3,5,0,6.9,0"/>
              <path d="M121.6,127.6c4.5,0.8,8.8,2.3,12.9,4.4c1.6,0.7,3.1,1.6,4.6,2.5c0.7,0.6,1.4,1.1,2.2,1.6c-0.8,8.6-0.8,17.2,0,25.7c1.7,22.8,8.3,44.9,19.3,64.8c1.3,2.3,2.5,4.6,4,6.8c0.2,0.4,0.4,0.7,0.7,1.1c4.2,6.9,9.1,13.5,14.6,19.5c1.8,2.3,3.7,4.5,5.8,6.6c1.6,1.8,4.1,4.2,4.1,4.2c5.7,5.8,11.9,11,18.5,15.7c1.9,1.4,3.9,2.7,6,3.9c0.6,0.4,1.3,0.8,1.9,1.3c-5.5,2.4-11.3,4.3-17,6.3c-2.9,0.7-5.8,1.5-8.8,2.2c-6,1.4-12,2.6-18.2,3.4c-1,0-6.5,0.6-9.3,0.6c-15.5,0.9-31-0.5-46.1-4.1c-1.4-0.4-2.1-0.5-3.3-0.9c-2.2-0.5-4.2-1.3-6.4-1.8c-2.2-0.5-2.5-0.8-4.2-1.5c-7.2-2.5-14.2-5.5-21-9c-1.3-0.6-2.6-1.3-3.9-2.1c-4.7-2.5-9.2-5.7-13.7-8.4c-5.7-4.2-11.2-8.6-16.5-13.4c-0.7-1-2.2-1.4-2.7-2.7c-1.3-1-2.4-2.2-3.5-3.4c-0.5-0.5-1-1-1.5-1.5c-2.3-2.3-4.2-5-6.3-6.9c-2.5-3.2-5-6.7-7.7-10c-2.9-4.2-5.6-8.6-8-13.1c-0.7-1.3-1.2-2.3-1.8-3.3c-2.7-5.1-5.1-10.4-7.2-15.8c-0.4-0.9-0.8-1.8-1.1-2.7c-0.4-1.1-0.8-2.3-1.2-3.4c3.6-2.7,6.7-5,10-8.1c4.4-4.2,9.6-7.9,14.2-11.8c4.7-3.9,10.3-8.5,15.5-12.5c0.9-1,1.9-1.8,3-2.6c1.1-1,2.3-2,3.6-2.8c0.8-1.2,2-1.5,2.9-2.5c0.9-1.1,2.7-2.1,3.9-3.1c5-4.3,9.4-7.8,14.3-12c1.2-1,1.6-1.2,2.4-2c3.5-2.5,7.1-4.6,11-6.4c2.1-1,4.2-1.8,6.3-2.6c7.5-2,15.4-2.5,23.1-1.5L121.6,127.6z"/>
              <path d="M313.2,0c3.8,0,7.6,0.2,11.4,0.7c9.7,0.9,19.3,2.7,28.6,5.4c2,0.8,4.3,1.3,6.3,2.1c3.8,1.3,7.5,2.8,11.1,4.5c-10,7.1-19.6,14.9-28.6,23.2c-1.7,1.6-2.8,3.2-4.4,5c-1.3,1-2.4,2.1-3.4,3.4c-0.9,1.2-2.2,2.2-3,3.4l-1.2,1.6c-1,0-1.7-0.9-2.6-1c-6.9-3.3-14.3-5.4-22-6c-0.9-0.5-2,0-3-0.4c-1-0.4-2.7,0-4.4,0c-3.9-0.1-7.8,0.4-11.5,1.6c-2.9,0.6-5.7,1.5-8.4,2.9c-4.1,1.8-7.9,4.3-11.3,7.3c-1.1,1.8-3.2,2.9-4.1,5c-0.6,0.5-1,1.1-1.4,1.8h-0.4c-2.3,2.6-4.2,5.6-5.7,8.7c-3,4.8-5.4,10-7.1,15.4c-1,2.5-1.5,4.2-2.4,6.8c-0.9,2.6-1.3,4.5-2.3,7.1c-1.4,3.9-3.2,7.7-5.6,11.1c-1.2,1.3-1.7,2.3-2.7,3.3c-2.6,2.8-5.5,5.6-8.5,8.4c-3,2.8-5.6,5-8.4,7.6c-7.2,6.6-14.2,12.8-21.4,19.4c-0.5,0.3-0.9,0.6-1.3,1.1c-4.7,4.4-10,8.5-14.4,12.9c-0.7,0.7-2,1.6-2.6,2.1c-6.5,6-13.6,11.9-20,18.2c-0.3,0.3-0.6,0.6-1,0.8c0-0.6,0-1.4-0.4-1.9c-0.5-1.6-0.8-3.3-0.9-5c-0.8-3.8-1-7.1-1.6-11c0-3-0.5-6.3-0.7-9.5v-5c0.2-7.3,0.8-14.6,1.9-21.8c0-0.6,0-1.1,0.4-2.1c0-2.5,0.6-3.8,1-6.1c0-0.9,0.4-1.5,0.5-2.1c0.5-1.8,0.8-3.8,1.4-5.6c0.2-1,0.4-2,0.8-3c1.8-6.1,3.9-12.1,6.4-17.9c0.6-0.3,0-1,0.6-1.4c2.3-4.5,4.3-9.1,6.8-13.6c0.7-1.3,1.5-2.4,2.4-3.6c3.2-5.5,6.9-10.7,10.9-15.7c5.6-6.7,11.6-13,18-18.9c4.2-3.6,8.5-6.9,12.8-10l1.9-1.3c2.3-1.4,4.2-3,6.4-4.2c3.1-2.1,6.3-3.8,9.4-5.6c1,0,1.7-1.1,2.7-1.3c2.6-1.2,5-2.6,7.7-3.6c1.7-0.8,2.5-1.1,4.1-1.9l4.2-1.6c1.6-0.5,2.9-1.1,4.4-1.5l2.8-1c7.5-2.3,15.2-4.1,23-5.4c7.2-1,14.5-1.7,21.9-1.9c2,0.3,5,0,6.9,0"/>
              <path d="M275.9,127.6c4.5,0.8,8.8,2.3,12.9,4.4c1.6,0.7,3.1,1.6,4.6,2.4c0.7,0.6,1.4,1.1,2.2,1.6c-0.8,8.6-0.8,17.2,0,25.7c1.7,22.8,8.2,44.9,19.3,64.8c1.3,2.3,2.5,4.6,4,6.8c0.2,0.4,0.4,0.7,0.7,1.1c4.2,6.9,9.1,13.5,14.6,19.5c1.8,2.3,3.8,4.5,5.8,6.6c1.6,1.8,4.1,4.2,4.1,4.2c5.7,5.8,11.9,11,18.5,15.7c1.9,1.4,3.9,2.7,6,3.9c0.6,0.4,1.3,0.8,1.9,1.3c-5.5,2.4-11.3,4.3-17,6.3c-2.9,0.7-5.8,1.5-8.8,2.2c-6,1.4-12,2.6-18.2,3.4c-1,0-6.5,0.6-9.3,0.6c-15.5,0.9-31-0.5-46.1-4.1c-1.4-0.4-2.1-0.5-3.3-0.9c-2.2-0.5-4.2-1.3-6.4-1.8s-2.5-0.8-4.2-1.5c-7.2-2.5-14.2-5.5-21-9c-1.3-0.6-2.6-1.3-3.9-2.1c-4.7-2.5-9.2-5.7-13.7-8.4c-5.7-4.2-11.2-8.6-16.5-13.4c-0.7-1-2.2-1.4-2.7-2.7c-1.3-1-2.4-2.2-3.5-3.4c-0.5-0.5-1-1-1.5-1.5c-2.3-2.3-4.2-5-6.3-6.9c-2.5-3.2-5-6.7-7.7-10c-2.9-4.2-5.6-8.6-8-13.1c-0.7-1.3-1.2-2.3-1.8-3.3c-2.6-5.1-5-10.4-6.9-15.9c-0.4-0.9-0.8-1.8-1.1-2.7c-0.4-1.1-0.8-2.3-1.2-3.4c3.6-2.7,6.7-5,10-8.1c4.4-4.2,9.6-7.9,14.2-11.8c4.7-3.9,10.3-8.5,15.5-12.5c0.9-1,1.9-1.8,3-2.6c1.1-1,2.3-2,3.6-2.8c0.8-1.2,2-1.5,2.9-2.5c0.9-1.1,2.7-2.1,3.9-3.1c5-4.3,9.4-7.8,14.3-12l2.4-2c3.5-2.5,7.1-4.6,11-6.4c2.1-1,4.2-1.8,6.3-2.6c7.5-2,15.4-2.5,23.1-1.5L275.9,127.6z"/>
              <path d="M475.4,0.2c4.5,0.4,8.4,0.9,12.8,1.5c6,0.8,11.9,2.1,17.8,3.8c1.3,0.6,3.1,0.8,4.6,1.4c5.7,1.7,11.2,3.8,16.6,6.3c4.7,1.8,8,3.9,12.3,5.9c1.9,1,4,2.3,6.1,3.4l7.7,5c2.1,1.5,7.1,5.3,9.4,7.3c1.6,1.4,4.7,3.8,6.1,5c4.2,3.7,8.2,7.7,11.9,11.9c1.8,1.9,2.2,2.7,3.5,4c1.3,1.3,2.8,3.7,4.3,5.6c-8.5,2.5-17.2,4.2-26.1,5c-2.1,0.3-4.2,0.5-6.3,0.5c-9,0.8-18.1,0.4-27-1.3c-2.3-0.3-4.6-0.7-6.9-1.3c-1.3-0.5-5.4-1.5-7.6-2.1c-5-1.6-9.9-3.4-14.7-5.5l-3.5-1.5c-2.5-1.2-5-2.5-7.7-3.8c-7-3.3-14.3-6-21.9-8.1c-2.9-0.6-5.8-1.1-8.8-1.4c-2.6-0.1-5.2,0-7.8,0.3c-1.9,0-3.9,0.2-5.8,0.5c-3.8,0.8-7.5,1.9-11.2,3.3c-2.9,1.5-5.7,3.1-8.4,5c-1.6,1.2-3.2,2.5-4.6,3.9c-0.7,0.8-2.1,2.5-3.5,4.1c-1.3,1.3-2.4,2.8-3.4,4.4c-1.5,2-4.2,7.2-5,8c-0.8,2-2,3.9-3,6.2c-0.3,1-0.8,1.9-1.2,2.8c-1,2.3-1.7,3.9-2.7,7c-1,3.1-2.1,6.6-2.9,8.9c-1.2,3.6-2.9,7.1-5,10.3c-1.7,2.7-3.6,5.2-5.8,7.5c-1.4,1.6-2.9,3.1-4.5,4.4l-3,2.8c-15.8,13.7-30.5,27.1-46.4,40.8c-7.3,6.7-15,13.4-22.4,20.3c-0.3-1.2-0.6-2.3-0.8-3.5c-0.5-2.4-1-4.9-1.3-7.4c0-1.6-0.3-3.1-0.4-4.1c-0.3-3.5-0.7-6.9-0.8-10c-0.4-2.5,0-5,0-7.2v-3c-0.4-0.3,0-0.5,0-0.9c0.1-5.4,0.6-10.9,1.6-16.2c0.5-3,0.7-4.3,1-6.7c0.4-1.3,0.7-2.6,0.9-4c0.5-1.4,0.8-2.8,1.1-4.3c1.2-5,2.7-9.9,4.5-14.8c0.4-0.9,0.7-1.8,1.1-3.1c1.7-4.4,3.1-6.6,4.3-10c1.2-1.6,1.2-3.1,2.6-5c0.7-1.5,1.6-3,2.5-4.4c1.2-2,2.3-4,3.5-5.9c0.4-0.5,0.7-0.9,1-1.5c2.6-4.1,10-13.3,10.6-14.2c1.9-2.4,5-5.3,7.1-7.6c2.1-2.6,4.4-4.9,6.9-7.1c1.7-1.7,3-2.8,4-3.6c5.3-4.4,10.8-8.5,16.6-12.3c4.3-2.8,8.7-5.4,13.2-7.7c1.3-0.8,5-2.5,6.4-3.1c1.4-0.6,2.8-1.3,4.5-2c1.7-0.7,2.1-1,3.7-1.5c3.5-1.6,6.9-2.5,10.8-3.9c0.9-0.2,1.7-0.4,2.6-0.8c3.6-1.1,7.3-2,11-2.7c1.5,0,2.7-0.6,4.4-1c9.7-2,19.5-3,29.4-3h8.1L475.4,0.2z"/>
              <path d="M430.1,126.7c4.2,1,8.3,2.4,12.2,4.3c3.1,1.5,6.2,3.2,9.2,5c0,0,2.1,1.6,2.6,1.8c1.8,1.3,4,2.9,5.5,4.2c4.6,3.7,8.8,7.7,12.7,12.2c4.5,5.5,8.7,10.5,13,16c3.2,3.4,6.7,7.9,10,11.2c4.4,4,9.1,7.7,14.1,11c1.6,1.4,7.3,5,10,6.5c2.6,1.6,5.3,3.1,8.1,4.5c2.2,1.2,3.8,2,5.8,2.9c5.8,2.6,11.8,4.9,17.9,6.9c13.6,3.9,27.8,5.7,42,5.3l7.2-0.3c-0.9,2-2,4-3.2,5.8c-3.1,4.9-6.4,9.6-10,14.1c0,0.3-0.3,0.6-0.6,0.8c-6.3,8-13.3,15.3-21,21.8c-1.9,2-4,3.8-6.2,5.5c-2.5,2-5.1,3.9-7.8,5.6c-5.9,4-12.1,7.6-18.6,10.8c-2.3,0.9-4.4,2.1-6.7,2.9c-3.9,1.9-8,3.1-11.9,4.7c-2.3,0.7-10,3.1-11.9,3.4c-8.3,2.3-16.8,3.7-25.3,4.3c-18,1.8-36.1,0.3-53.5-4.4c-6.6-1.6-13.1-3.6-19.4-6.2c-7.8-3.2-15.3-7-22.6-11.3c-4-2.5-8.1-5-12-7.9c-2.5-1.9-5-3.9-7.2-5.6c-2.2-1.8-5-3.9-7.5-6.5c-1.4-1.5-5-5.3-6-6.1c-4-4.4-7.8-8.7-11.3-13.4c-0.5-0.7-1-1.4-1.5-2.1c-2.6-3.7-5-7.5-7.2-11.5c-0.7-1.2-2.1-3.6-3.1-5c-3.1-6.1-5.9-12.4-8.3-18.8c-0.9-1.9-1.7-4-2.2-6.1c3.7-3.1,6.5-5.3,10-8c4.3-3.8,8.9-7.3,13.3-10.9l2.5-2c0,0,0-0.5,0.4-0.3c4.4-3.7,12.9-10.3,14.2-11.6c1.3-1.3,8.2-6.7,9.4-7.6c1.1-0.8,2.2-1.6,3.2-2.5c3.1-2.6,12.2-9.5,13.8-10.9c3.2-2.6,6.5-5,10-7.2c3.1-1.9,6.5-3.5,10-4.6c2.3-0.8,4.7-1.3,7.2-1.8c7.1-0.8,14.3-0.5,21.3,1"/>
            </svg>
          </div>
        </div>
        <div className="text-center">
          <Link to="/services/clothing-brands/" className="button btn-bordered">
            View Clothing Brands <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><polygon points="9.3,1.3 7.9,2.7 12.2,7 0,7 0,9 12.2,9 7.9,13.3 9.3,14.7 16,8 "></polygon></svg>
          </Link>
        </div>
      </Fade>
    </div>
  </section>
)

export default ClothingBrands
