import React from "react"
import BackgroundImage from 'gatsby-background-image'
import { Fade } from "react-awesome-reveal";
import { Link } from "gatsby"

const HomeBanner = ({ title, caption, image }) => (
  <section className="banner home-banner">
    <BackgroundImage Tag="section" className="banner-image" fluid={image} backgroundColor={`#000`}>
      <div className="banner-overlay-horizontal" />
      <div className="banner-overlay-vertical" />
      <div className="banner-content">
        <div className="container">
          <div className="row">
            <div className="col">
              <Fade triggerOnce>
                <h1>{title}</h1>
                <div dangerouslySetInnerHTML={{ __html: caption }} />
                {/* <Link to="/get-a-quote/" className="button">Get A Quote</Link> */}
              </Fade>
            </div>
            <div className="col">
            </div>
          </div>
        </div>
      </div>
      <Fade triggerOnce>
        <div className="banner-accreditation">
          <p><small>Yellow-Eyed Penguin Trust</small></p>
          <p>Team Vest & Embroidery</p>
        </div>
      </Fade>
    </BackgroundImage>
  </section>
)

export default HomeBanner
