import React from "react"
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"
import { Fade } from "react-awesome-reveal";

const Testimonials = ({ testimonialContent }) => {

  const testimonialText = testimonialContent.testimonial_text;
  const testimonialAuthor = testimonialContent.author;
  const testimonialBusiness = testimonialContent.business;
  const testimonialImage = testimonialContent.banner_image.childImageSharp.fluid;
  const testimonialAuthorImage = testimonialContent.author_image.childImageSharp.fluid;

  return (
    <section className="testimonials">
      <div className="row no-gutters">
        <div className="col col-one-half">
          <div className="testimonial-content">
            <Fade triggerOnce>
              <div className="quote">
                <p className="testimonial-text">{testimonialText}</p>
                <p className="testimonial-author">{testimonialAuthor} <strong>{testimonialBusiness}</strong></p>
              </div>
            </Fade>
            <Fade triggerOnce>
              <div className="controls">
                <Img className="author-image" fluid={testimonialAuthorImage} />
              </div>
            </Fade>
          </div>
        </div>
        <div className="col col-one-half">
          <div className="testimonial-image">
            <BackgroundImage Tag="section" className="banner-image" fluid={testimonialImage} backgroundColor={`#000`} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
