import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import remark from 'remark'
import remarkHTML from 'remark-html'
import HomeBanner from "../components/homeBanner"
import HomeLogos from "../components/homeLogos"
import ClothingBrands from "../components/clothingBrands"
import Sections from "../components/sections"
import Testimonials from "../components/testimonials"

const IndexPage = ({ data }) => {
  const toHTML = value => remark().use(remarkHTML).processSync(value).toString();

  const title = data.markdownRemark.frontmatter.title;
  const bannerTitle = data.markdownRemark.frontmatter.banner.small_title;
  const bannerCaption = toHTML(data.markdownRemark.frontmatter.banner.caption);
  const bannerImage = data.markdownRemark.frontmatter.banner.banner_image.childImageSharp.fluid;
  const sectionsContent = data.markdownRemark.frontmatter.sections;
  const testimonialContent = data.markdownRemark.frontmatter.testimonial;
  const clothingLogosTitle = data.markdownRemark.frontmatter.clothing_logos.title;
  const clothingLogosCaption = data.markdownRemark.frontmatter.clothing_logos.caption;

  return (
    <Layout>
      <SEO title={title} />
      <HomeBanner title={bannerTitle} caption={bannerCaption} image={bannerImage} />
      <HomeLogos />
      <Sections sectionsContent={sectionsContent} />
      <ClothingBrands title={clothingLogosTitle} caption={clothingLogosCaption} />
      <Testimonials testimonialContent={testimonialContent}/>
    </Layout>
  )
}

export const query = graphql`
query {
  markdownRemark(frontmatter: { template_key: { eq: "home" } }) {
    frontmatter {
      title
      banner {
        small_title
        caption
        banner_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      sections {
        subtitle
        title
        caption
        button_text
        url
        external_link
        image {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      clothing_logos {
        title
        caption
      }
      testimonial {
        testimonial_text
        author
        business
        banner_image {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        author_image {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    html
  }
}
`

export default IndexPage
